// Why is this in cs2-marketing? Shouldnt this be csgo/Leaderboard?

import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import { updateRoute } from "@/__main__/router.mjs";
import { SelectRegion } from "@/game-cs2-marketing/components/Selects.jsx";
import { RankDistribution } from "@/game-cs2-marketing/LeaderboardCharts.jsx";
import useLeaderboard from "@/game-cs2-marketing/utils/useLeaderboard.jsx";
import { REGIONS } from "@/game-csgo/constants.mjs";
import InfoIcon from "@/inline-assets/blitz-info-border.svg";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";

const StyledFilterBar = styled(FilterBar)`
  display: flex;
  gap: var(--sp-2);
  align-items: center;
`;

const LeaderboardTable = () => css`
  .player-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .player-image {
    border-radius: 50%;
  }
  .player-name {
    display: block;
    color: var(--shade0);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    @container content-card (width <= 700px) {
      max-width: 8ch;
    }
  }
  .player-rank-icon {
    width: calc(var(--sp-1) * 12.5);
  }

  td[data-primary="true"] {
    width: 99%;
  }
`;

const ChartCard = styled(Card)`
  .chart-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--shade2);

    .title {
      display: flex;
      align-items: center;
      color: var(--shade0);
      svg {
        display: none;
        color: var(--shade2);
        width: var(--sp-4);
        height: var(--sp-4);
        margin-left: var(--sp-2);
      }
    }
  }

  .y-axis {
    color: var(--shade3);
  }

  .bars {
    rect {
      cursor: pointer;
    }
  }
`;

const TITLE = ["common:navigation.cs2Leaderboard", "CS2 Leaderboards"];
const DESCRIPTION = [
  "",
  "Stay on top of the competitive CS2 scene with our Blitz powered Global Leaderboard. We provide up to date player stats, and performance metrics. Discover how you compare with the best in the game and identify opportunities for improvement.",
];

function Leaderboard() {
  const { currentPath, parameters, searchParams } = useRoute();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [tierDistribution, setTierDistribution] = useState(false);

  const distributionTip = ReactDOMServer.renderToStaticMarkup(
    <p className="type-caption--semi">
      {t(
        "cs2:leaderboard.distributionTip",
        "Data is limited by API to the top 1000 players.",
      )}
    </p>,
  );

  const updateRegion = (region) => {
    const newSearchParams = new URLSearchParams({
      seasonId: "1",
      region: region,
    });

    updateRoute(currentPath, newSearchParams);
  };

  const region_param = searchParams.get("region");
  const region = REGIONS.map((region) => region.key).includes(region_param)
    ? region_param
    : "global";

  const { cols, rows, stats } = useLeaderboard({
    type: parameters[0],
    region: region,
  });

  const isLoaded = useIsLoaded();
  const isLoading = !rows || !isLoaded;

  return (
    <PageContainer>
      <PageHeader
        title={t(TITLE)}
        links={[
          {
            url: `/cs2/leaderboard`,
            text: t("csgo:modes.premier", "Premier"),
          },
        ]}
      />
      <StyledFilterBar hiddenItems={1}>
        <TextInput
          defaultValue={searchText}
          placeholder={t("common:searchPlayer", "Search Player")}
          onValueChange={(s) => setSearchText(s)}
        />
        <SelectRegion selected={region} onChange={updateRegion} />
      </StyledFilterBar>
      <ChartCard>
        <div className="chart-header">
          <p className="type-title--bold title">
            {tierDistribution
              ? t(
                  "cs2:leaderboard.playerDistributionByTier",
                  "Player Distribution by Tier",
                )
              : t(
                  "cs2:leaderboard.avgWinRateByTier",
                  "Average Win Rate by Tier",
                )}
            <InfoIcon data-tooltip={distributionTip} />
          </p>
          <ToggleSwitch
            value={tierDistribution}
            onChange={() => setTierDistribution(!tierDistribution)}
            labelText={t("cs2:tierDistribution", "Tier Distribution")}
            className="toggle-switch"
          />
        </div>
        <RankDistribution stats={stats} tierDistribution={tierDistribution} />
      </ChartCard>
      <DataTable
        cols={cols}
        rows={!isLoading ? rows : null}
        indexColHighlight
        className={LeaderboardTable()}
        sortCol={0} // Ranking
        sortColTiebreak={5} // Winrate
        sortDir="ASC"
        searchText={searchText}
        searchCol={2}
      />
    </PageContainer>
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default Leaderboard;
