import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { getCSRankingColor } from "@/game-cs2-marketing/utils.mjs";
import RankPlateShape from "@/inline-assets/cs-rank-plate.svg";

const RankPlate = styled("div")`
  position: relative;

  svg {
    color: ${(props) => props.color};
  }

  .label {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: calc(var(--sp-1) * 3.25);
  }

  span {
    color: ${(props) => props.color};
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.75);
    font-family: "Inter";
    font-style: italic;
    font-weight: 700;
    line-height: var(--sp-4);

    &:first-child {
      font-size: var(--sp-3_5);
      letter-spacing: -0.126px;
    }

    &:last-child {
      font-size: var(--sp-3);
      letter-spacing: -0.108px;
    }
  }
`;

function Ranking({ csr, isPrivate }) {
  const { t } = useTranslation();
  const fillColor = getCSRankingColor(csr);

  const firstDigits = Math.floor(csr / 1000);
  const lastDigits = ("000" + (csr % 1000)).slice(-3);
  return (
    <RankPlate color={fillColor}>
      <RankPlateShape />
      <div className="label">
        {!isPrivate ? (
          <>
            <span>{firstDigits},</span>
            <span>{lastDigits}</span>
          </>
        ) : (
          <span>&nbsp;&nbsp;&nbsp;{t("common:notApplicable", "N/A")}</span>
        )}
      </div>
    </RankPlate>
  );
}

export default Ranking;
