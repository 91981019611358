import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { readState } from "@/__main__/app-state.mjs";
import Ranking from "@/game-cs2-marketing/components/Ranking.jsx";
import { REGIONS } from "@/game-csgo/constants.mjs";
import { getProfileAvatar } from "@/game-csgo/utils.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const percentOptions = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const leaderboardTypes = {
  premier: "premier",
  competitive: "competitive",
};

const useLeaderboard = ({ type = leaderboardTypes.premier, region }) => {
  const { t } = useTranslation();

  const {
    cs2: {
      leaderboard: { premier },
    },
  } = useSnapshot(readState);

  const allStats = useMemo(() => {
    const leaderboard = Array.isArray(premier[region]) ? premier[region] : [];
    return leaderboard.map((entry) => {
      return {
        playerName: entry.isPrivate
          ? t("cs2:leaderboard.privatePlayer", "Private Player")
          : entry.name,
        playerRegion: REGIONS.find((r) => r.steam_id === entry.region),
        csr: entry.score,
        winRate:
          (entry.wins + 0.5 * entry.ties) /
          (entry.wins + entry.losses + entry.ties || 1),
        wins: entry.wins,
        losses: entry.losses,
        isPrivate: entry.isPrivate,
        rank: entry.rank,
      };
    });
  }, [premier, region, t]);

  const cols = [
    {
      display: "#",
      alight: "center",
    },
    {
      display:
        type === leaderboardTypes.premier
          ? t("common:rating", "Rating")
          : t("common:ranking", "Ranking"),
      align: "center",
    },
    { display: t("common:player", "Player"), align: "left", primary: true },
    { display: t("common:wins", "Wins"), align: "right", isStat: true },
    { display: t("common:losses", "Losses"), align: "right", isStat: true },
    { display: t("common:winPercent", "Win %"), align: "right", isStat: true },
    {
      display: t("common:region", "Region"),
      align: "center",
    },
    // {
    //   display: t("common:kda", "KDA"),
    //   align: "right",
    //   isStat: true,
    //   tooltip: t("common:kdaTooltip", "Kills / Assists / Deaths"),
    // },
    // {
    //   display: t("common:adr", "ADR"),
    //   align: "right",
    //   isStat: true,
    //   tooltip: t("common:averageDamagePerRound", "Average Damage / Round"),
    // },
    // {
    //   display: t("common:stats.hsPercent", "HS %"),
    //   align: "right",
    //   isStat: true,
    //   tooltip: t("common:headshotPerc", "Headshot %"),
    // },
    // {
    //   display: t("common:acc", "ACC"),
    //   align: "right",
    //   isStat: true,
    //   tooltip: t("common:accuracy", "Accuracy"),
    // },
    // {
    //   display: t("common:pwin", "PWin %"),
    //   tooltip: t("cs:pistolWinPercent", "Pistol Win %"),
    //   align: "right",
    //   isStat: true,
    // },
  ];

  const stats = allStats.filter(
    ({ playerRegion }) => region === "global" || playerRegion.key === region,
  );

  const tableData = useMemo(() => {
    if (!stats || stats.length === 0 || stats instanceof Error) return null;

    return stats.map((stat, rowIndex) => {
      const {
        playerName,
        playerRegion,
        csr,
        winRate,
        wins,
        losses,
        isPrivate,
        rank,
      } = stat;

      return [
        {
          display: rank,
          value: rank,
        },
        {
          display: <Ranking csr={csr} isPrivate={isPrivate} />,
          value: csr,
        },
        {
          display: (
            <div className="player-meta">
              <img
                className="player-image"
                src={getProfileAvatar()}
                loading={rowIndex > 20 ? "lazy" : "eager"}
                width={32}
                height={32}
                alt={playerName}
              />
              <div>
                <span
                  className="player-name type-body2-form--bold"
                  data-tip={playerName}
                >
                  {playerName}
                </span>
              </div>
            </div>
          ),
          value: playerName,
          // link: `/cs2/profile/${steamId}`,
        },
        {
          display: !isPrivate ? getLocaleString(wins) : "",
          value: wins,
        },
        {
          display: !isPrivate ? getLocaleString(losses) : "",
          value: losses,
        },
        {
          display: !isPrivate ? getLocaleString(winRate, percentOptions) : "",
          value: winRate,
        },
        {
          display: !isPrivate ? (
            playerRegion.icon ? (
              React.createElement(playerRegion.icon)
            ) : (
              <Tag
                size={"sm"}
                color={"var(--shade2)"}
                text={playerRegion.short}
              />
            )
          ) : (
            ""
          ),
          value: playerRegion.key,
        },
      ];
    });
  }, [stats]);

  return {
    cols: cols,
    rows: tableData,
    stats,
  };
};

export default useLeaderboard;
