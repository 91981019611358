import React from "react";
import { styled } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { REGIONS } from "@/game-csgo/constants.mjs";

const Styled = {
  Tag: styled(Tag)`
    width: var(--sp-6);

    &:before {
      background: transparent;
    }
  `,
};

export function SelectRegion({ selected, onChange }) {
  return (
    <Select
      onChange={onChange}
      selected={selected}
      options={REGIONS.map(({ key, text, short, icon }) => {
        return {
          icon: icon ? (
            React.createElement(icon)
          ) : (
            <Styled.Tag size={"sm"} color={"var(--shade1)"} text={short} />
          ),
          text,
          value: key,
        };
      })}
    />
  );
}
