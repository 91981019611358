import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import {
  getCSRankingColor,
  getCSRankingRange,
} from "@/game-cs2-marketing/utils.mjs";
import Chart from "@/shared/Chart.jsx";
import { Bar } from "@/shared/ComponentsForChart.jsx";
import { calcRate, displayRate } from "@/util/helpers.mjs";

const RANK_GROUPS = [5000, 10000, 15000, 20000, 25000, 30000, 100000];

const Tooltip = styled("div")`
  p:first-child {
    margin-bottom: var(--sp-1);
  }

  .stat-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--sp-2);
  }

  .stat-title {
    color: var(--shade0-75);
  }

  .stat-value {
    font-weight: 625;
  }

  .highlight {
    color: var(--turq);
    font-weight: 725;
  }
`;

export function RankDistribution({ stats, tierDistribution }) {
  const { t } = useTranslation();

  const chartData = {};

  stats
    .filter((s) => !s.isPrivate)
    .forEach((s) => {
      const group = RANK_GROUPS.find((g) => s.csr < g);
      if (!chartData[group]) chartData[group] = { total: 0, count: 0 };
      chartData[group].total += s.winRate;
      chartData[group].count += 1;
    });

  const data = Object.entries(chartData)
    .filter(([, { count }]) => count !== 0)
    .map(([range, { total, count }]) => {
      return {
        xValue: range,
        winRate: calcRate(total, count) * 100,
        playerRatio: calcRate(count, stats.length) * 100,
        count,
      };
    });

  return (
    <Chart
      data={data}
      xField="xValue"
      yField={tierDistribution ? "playerRatio" : "winRate"}
      height={280}
      xAxisConf={{
        show: true,
        type: "band",
        tickFormat: (value) => getCSRankingRange(value),
        padding: 0.4,
      }}
      yAxisConf={{
        show: true,
        type: "linear",
        tickValues: [0, 50, 100],
        orientation: "right",
      }}
      margin={{ left: 0, right: 20, top: 20, bottom: 20 }}
    >
      <Bar
        data={data}
        color={(value) => getCSRankingColor(value - 1, 0.86)}
        className="bars"
        tooltipContent={(d) => (
          <Tooltip>
            <p className="type-caption--bold shade0">
              {t("common:tier", "Tier")} {getCSRankingRange(d.xValue)}
            </p>
            <div className="stat-line">
              <p className="type-caption stat-title">
                {t("common:avgWinRate", "Avg. Win Rate")}
              </p>
              <p className="type-caption--semi highlight stat-value">
                {displayRate(d.winRate, 100, 0, 1)}
              </p>
            </div>
            <div className="stat-line">
              <p className="type-caption stat-title">
                {t("common:players", "Players")}
              </p>
              <p className="type-caption--semi shade0 stat-value">{d.count}</p>
            </div>
            <div className="stat-line">
              <p className="type-caption stat-title">
                {t("common:ratio", "Ratio")}
              </p>
              <p className="type-caption--semi shade0 stat-value">
                {displayRate(d.count, stats.length, 0, 1)}
              </p>
            </div>
          </Tooltip>
        )}
      />
    </Chart>
  );
}
